import LanguageModel from '../models/language-model.js'
import $ from 'jquery'
import { each, get } from 'lodash'

import ProductPageFormOperator from './product-page-form-operator.js'

export default class CbOperator extends ProductPageFormOperator {

  constructor(productPage, fpdManager) {
    super(productPage)
    this.fpdManager = fpdManager
    this.$fpdDoneButton = null
  }

  get optionsManager() {
    return this.productPage.optionsManager
  }

  initDoneListener() {
    this.$fpdDoneButton ||= $(this.productPage.taModal.fpdDone || '.fpd-done')

    this.$fpdDoneButton.on('click.fpd', this.doneHandler.bind(this))
  }

  doneHandler() {
    this.fpdManager.instance.currentViewInstance.fabricCanvas.resetZoom()
    this.replaceProductImage()
    if(this.optionsManager.isDoneAsAtc()) {
      this.productPage.atcOperator.triggerAtcClick()
    }
  }

  openAndCloseModal() {
    this.$customizeButton = $(this.productPage.taModal.customizeButton)
    this.$closeButton = $(this.productPage.taModal.fpdClose || 'fpd-actions-bar .fpd-close:not(.fpd-done)')
    
    if(this.$customizeButton.length > 0 && this.$closeButton.length > 0) {
      this.$customizeButton.click()
      this.$closeButton.click()
    }
  }

  replaceProductImage() {
    let $productImages = $(this.productPage.taModal.mainImage)

    if($productImages.length > 0) {
      this.createViewImage().then(previewDataUrl => {
        const that = this
        $productImages.each((_index, $productImage) => {
          $productImage = $($productImage)
          if($productImage.prop("tagName") != "IMG") {
            that.productPage.taModal.mainImage += " img#fpd-product-image"
            $productImage.empty().append("<img id='fpd-product-image' />")
            $productImage = $(this.productPage.taModal.mainImage)
          }

          $productImage.attr('src', previewDataUrl)
          if($productImage.attr('srcset')) {
            $productImage.attr('srcset', previewDataUrl)
          }
          if($productImage.attr('data-srcset')) {
            $productImage.attr('data-srcset', previewDataUrl)
          }
          if($productImage.attr('data-zoom')) {
            $productImage.attr('data-zoom', previewDataUrl)
          }
          if(that.optionsManager.hasCallback('mainImageReplaceCallback')) {
            that.optionsManager.invokeCallback('mainImageReplaceCallback', $productImage, previewDataUrl)
          }
        })
      })
    }
  }

  createViewImage(viewIndex = null) {
    if(viewIndex === null) {
      viewIndex = this.fpdManager.instance.currentViewIndex
    }

    return new Promise((resolve, reject) => {
      this.fpdManager.instance.viewInstances[viewIndex].toDataURL(previewData => {
        resolve(previewData)
      })
    })
  }

  /**
   * Create a customize Button on the page. It does some magic
   * to add classes from the add to cart button and remove the
   * classes that are in the add to cart button selector.
   *
   * It add the button after the $addtoCartButton.
   *
   * Side Effects:
   * - Sets FPD.ta.modal, FPD.shopOptions with the customize button
   * ID fpd-customize-button
   *
   * @param  {jQuery} $addToCartButton The add to cart button.
   * @param  {string} customizeButtonId The id of the new button
   * @return {jQuery} created customize button.
   */
  createButton($addToCartButtons = null, customizeButtonId = "fpd-customize-button") {
    if($addToCartButtons == null) {
      $addToCartButtons = $(get(this.getAtcButtons(), '[0]'))
    }

    // setting selector
    const customizeButtonSelector = `#${customizeButtonId}`
    this.productPage.updateTa('modal.customizeButton', customizeButtonSelector)

    const buttonText = LanguageModel.lookup('customizeButton', 'Customize')
    const $customizeButton = $(`<span id='${customizeButtonId}'>${buttonText}</span>`)

    // button magic
    $customizeButton.attr("class", $addToCartButtons.attr("class"))

    let classes = this.parseCssSelectors(this.productPage.taProduct.addToCartFormSubmit)
    classes = classes["classes"]

    if (classes.length > 0) {
      each(classes, (class_str)=> {
        $customizeButton.removeClass(class_str)
      })
    }

    $addToCartButtons.after($customizeButton)

    return $customizeButton
  }

  parseCssSelectors(subselector) {
    var obj = { tags: [], classes: [], ids: [], attrs: [] };
    subselector.split(/(?=\.)|(?=#)|(?=\[)/).forEach( function(token) {
      switch (token[0]) {
        case '#':
           obj.ids.push(token.slice(1));
          break;
        case '.':
           obj.classes.push(token.slice(1));
          break;
        case '[':
           obj.attrs.push(token.slice(1,-1).split('='));
          break;
        default :
           obj.tags.push(token);
          break;
      }
    });
    return obj;
  }
}
