import { chain, each, keys, merge, mergeWith, pick, isEmpty, isString, has, set, isNil } from 'lodash'
import $ from 'jquery'

export default class OptionsManager {

  static buildWithDevDefaultOptions() {
    return new OptionsManager({
      shopifyDomain: 'fancyproductdesign2.myshopify.com',
      fpdAdminUrl: 'https://m20io3000.pagekite.me',
      apiUrl: 'https://m20io.pagekite.me'
    })
  }

  constructor(config = {}) {
    this._shopifyDomain = config.shopifyDomain
    this._fpdAdminUrl = config.fpdAdminUrl
    this._apiUrl = config.apiUrl
    this._loadingPromises = []
    this._subscription = null
    this.shopOptions = {}
  }

  get fpdAdminUrl() {
    return this._fpdAdminUrl
  }

  get apiUrl() {
    return this._apiUrl
  }

  get shopifyDomain() {
    return this._shopifyDomain
  }

  get subscription() {
    return this._subscription
  }

  set subscription(newValue) {
    this._subscription = newValue
  }

  get loadingPromise() {
    return Promise.all(this._loadingPromises)
  }

  get moneyFormat() {
    return FPD.money_format
  }

  get ta() {
    return FPD.ta
  }

  updateTa(path, value) {
    if(has(FPD.ta, path)) {
      set(FPD.ta, path, value)
    } else {
      throw new Error(`Path does not exist: ${path}.`)
    }
  }

  /**
   * This methods allows updating the theme abstraction from the plugin options
   * they don't allways have shopify_prefrix so it's added in case.
   *
   * It updates the FPD.ta object.
   *
   * @param  {Object} options options includeing TA information
   */
  updateTaFromOptions(options) {
    let prefixedOptions = {}

    each(keys(options), key => {
      if(!key.includes("shopify_") && key != "shopify") {
        prefixedOptions[`shopify_${key}`] = options[key]
      } else {
        prefixedOptions[key] = options[key]
      }
    })

    FPD.ta = merge(FPD.ta, this.mappedTADefintion(prefixedOptions))
  }

  updateShopOptions(path, value) {
    set(this.shopOptions, path, value)
  }

  loadShopOptions() {
    const shopOptionPromise = $.getJSON(this.getOptionsUrl()).then(shopOptions => {
      this.shopOptions = merge({}, shopOptions, FPD.shopOptions)
      window.FPD.shopOptions = this.shopOptions
    })

    const shopDataPromise = $.getJSON(this.getShopDataUrl()).then(response => {
      this.subscription = response.subscription
      window.FPD.subscription = this.subscription
      this.suggestedSelectors = response.suggested_selectors
      window.FPD.suggestedSelectors = this.suggestedSelectors
    })

    this._loadingPromises.push(shopOptionPromise, shopDataPromise)

    return shopOptionPromise
  }

  loadDesigns() {
    let allDesignsPromise = null
    if(!window.FPD.allDesignsJSON) {
      allDesignsPromise = fetch(this.getAllDesignsUrl()).
        then(response => response.json()).
        then(data => {
        window.FPD.allDesignsJSON = data.allDesignsJSON
      }).catch(() => {
        console.warn("Error loading all designs.")
      })
    }

    if(allDesignsPromise) {
      this._loadingPromises.push(allDesignsPromise)
    }
  }

  initFPDObject() {
    if(typeof window.FPD !== "object") {
      console.error("No global FPD object provided. Stopping execution.")
      console.info("Please contact your integration partner.")
      return
    }

    if(typeof window.Shopify !== "object") {
      console.error("No global Shopify object provided. Stopping execution")
      console.info("Please contact your integration partner.")
      return
    }

    this._shopifyDomain = window.Shopify.shop || this._shopifyDomain

    window.FPD = merge({}, this.getFPDDefaults(), window.FPD)
  }

  getAfterAtcStrategy() {
    if(this.shopOptions.shopify_add_to_form) {
      return 'submit_form'
    }
    if(this.shopOptions.shopify_no_redirect_after_add_to_cart) {
      if(this.shopOptions.shopify_no_reload_after_add_to_cart) {
        return 'trigger_event'
      }
      return 'refresh_page'
    }
    return 'redirect_to_cart'
  }

  initThemeAbstraction(shopOptions = null, suggestedSelectors = null) {
    if(shopOptions === null) {
      shopOptions = FPD.shopOptions || {}
    }

    if(suggestedSelectors === null) {
      suggestedSelectors = FPD.suggestedSelectors || {}
    }
    FPD.ta = FPD.ta || {}
    FPD.ta = mergeWith(
      {},
      this.getDefaultThemeAbstraction(),
      this.mappedTADefintion(suggestedSelectors),
      this.mappedTADefintion(shopOptions),
      FPD.ta,
      this.taMergeMethod
    )
  }

  taMergeMethod(objValue, srcValue, key, object, source, stack) {
    // overwrites empty strings in the merged objects
    if(isString(objValue) && isString(srcValue)) {
      if(isEmpty(objValue)) return srcValue
      if(isEmpty(srcValue)) return objValue
    }
    return undefined // use default merging
  }

  getFPDDefaults() {
    const fpdDefaults = {
      isModalModeActive: () => { return false }, //refactor
      lang: {},
      callbacks: {},
      shopOptions: {},
      designsUrl: `${this.fpdAdminUrl}/api/hosted/site_designs/?shop_domain=${this.shopifyDomain}`,
      lineItemCacheUrl: `${this.apiUrl}/api/fe/line_item_cache.json?shop=${this.shopifyDomain}`
    }

    return fpdDefaults
  }

  getDefaultThemeAbstraction() {
    return {
      product: {
        fpdContainer: "#fpd",
        addToCartForm: "form[action='/cart/add']",
        addToCartFormSubmit: "button[type='submit']",
        saveCustomization: "#fpd-save",
        price: '.fpd-shopify-price'
      },
      cart: {
        checkoutForm: "form[action='/cart']",
        checkoutFormItem: "*[data-line-item-id='##ITEM_KEY##']",
        itemPrice: ".fpd-item-price",
        itemImage: ".fpd-item-image",
        lineItemPrice: ".fpd-item-line-price",
        totalPrice: ".fpd-total-price",
        updateElement: "input[name='updates[]']"
      },
      cartJS: {
        cartElement: "form[action='/cart']",
        cartItem: ".cart-drawer__item",
        cartImage: ".cart-drawer__item-image"
      },
      modal: {
        mainImage: ".product_image img",
        customizeButton: "#fpd-customize-button"
      },
      config: {
        designerMode: "legacy"
      }
    }
  }

  mappedTADefintion(shopifyOptions) {
    let mappedTa = {}

    each(keys(shopifyOptions), key => {
      if(this.mapTaKey(key)) {
        set(mappedTa, this.mapTaKey(key), shopifyOptions[key])
      }
    })

    return mappedTa
  }

  mapTaKey(key) {
    let resultString = this.mapStaticTaKey(key)
    if(!isEmpty(resultString)) {
      return resultString
    }

    const startIndex = key.indexOf('_ta_')
    if(startIndex > - 1) {
      const relevantSubKey = key.substring(startIndex + 4).split('_')

      if(relevantSubKey.length >= 2) {
        resultString = `${relevantSubKey.shift()}.`

        each(relevantSubKey, (part, index) => {
          if(index == 0) {
            resultString += part
          } else {
            resultString += part.charAt(0).toUpperCase() + part.substring(1)
          }
        })
      }
    }
    if(!isEmpty(resultString)) {
      return resultString
    }
  }

  mapStaticTaKey(key) {
    return {
      shopify_ta_product_page_selector: 'product.fpdContainer',
      shopify_ta_product_page_form: 'product.addToCartForm',
      shopify_ta_product_page_add_cart_button: 'product.addToCartFormSubmit',
      shopify_ta_product_page_price: 'product.price',
      shopify_ta_product_save_customization: 'product.saveCustomization',
      shopify_ta_cart_page_form: 'cart.checkoutForm',
      shopify_ta_cart_page_row: 'cart.checkoutFormItem',
      shopify_ta_cart_page_row_item_price: 'cart.itemPrice',
      shopify_ta_cart_page_row_image: 'cart.itemImage',
      shopify_ta_cart_page_row_line_item_price: 'cart.lineItemPrice',
      shopify_ta_cart_page_total_price: 'cart.totalPrice',
      shopify_ta_cart_page_update_input: 'cart.updateElement',
      shopify_ta_product_page_main_image: 'modal.mainImage',
      shopify_ta_product_page_customize_button: 'modal.customizeButton',
      shopify_ta_product_designer_mode: 'config.designerMode'
    }[key]
  }


  getDefaultPluginOptions() {
    return {
      instagramRedirectUri: `https://${this.shopifyDomain}/`,
      templatesDirectory: `${this.apiUrl}/templates/`,
      fileServerURL: `${this.apiUrl}/api/fe/custom_images?shopify_domain=${this.shopifyDomain}`,
      customImageAjaxSettings: {
        url: `${this.apiUrl}/api/fe/custom_images?shopify_domain=${this.shopifyDomain}`,
        dataType: "json",
        method: "POST",
        data: {
          saveOnServer: 1, //image is uploaded to your server
          uploadsDir: null, //into this directory
          uploadsDirURL: null //and returns the new URL from this location
        }
      }
    }
  }

  getOptionsUrl() {
    return `${this.fpdAdminUrl}/api/hosted/site_options/?shop_domain=${this.shopifyDomain}&start_with[]=shopify&start_with[]=advanced_fabricjsTextureSize`
  }

  getAllDesignsUrl() {
    return `${this.fpdAdminUrl}/api/hosted/site_designs/?shop_domain=${this.shopifyDomain}`
  }

  getShopDataUrl() {
    return `${this.apiUrl}/api/fe/shops?shop=${this.shopifyDomain}`
  }

  getAiServiceUrl() {
    return `${this.apiUrl}/api/fe/genius.json?shopify_domain=${this.shopifyDomain}`
  }

  getAtcStrategy() {
    if(this.shopOptions.shopify_add_to_form) {
      return 'save_to_form'
    }
    if(this.shopOptions.shopify_atc_strategy) {
      return this.shopOptions.shopify_atc_strategy
    }

    return 'add_form_data'
  }

  getSaveButtonStrategy() {
    const urlParams = this.getUrlParams()

    if(urlParams && urlParams.get('sbs')) {
      return urlParams.get('sbs')
    }

    if(this.shopOptions.shopify_save_button_strategy) {
      return this.shopOptions.shopify_save_button_strategy
    }

    return 'save_to_form'
  }

  getFpdOverwrite() {
    return this.shopOptions.fpdOverwrite || {}
  }

  getFpdMoneyFormat() {
    return this.shopOptions.shopify_fpd_money_format
  }

  getPreviewLastView() {
    return this.shopOptions.shopify_preview_last_view || 1
  }

  getAtcExcludedFieldNames(defaultValues = []) {
    return this.shopOptions.shopify_atc_excluded_field_names || defaultValues
  }

  hasCallback(functionName) {
    if(isNil(FPD.callbacks)) {
      return false
    }
    return typeof FPD.callbacks[functionName] == 'function'
  }

  invokeCallback(functionName, ...args) {
    if(!this.hasCallback(functionName)) {
      throw new Error(`Invoking non existing callback: ${functionName}`)
    }
    return FPD.callbacks[functionName](...args)
  }

  getUrlParams() {
    const url = new URL(window.location.href)
    return new URLSearchParams(url.search)
  }

  getTextureSize() {
    return this.shopOptions.advanced_fabricjsTextureSize || 4096 * 2
  }
  
  isSortDesignsByTitle() {
    return this.shopOptions.shopify_disable_sort_by_title !== true
  }

  isDisableAtcUntilCustomization() {
    const disableAtc = this.shopOptions.shopify_disable_add_to_cart_until_customize ||
      this.shopOptions.shopify_disable_add_to_cart
    return disableAtc || false
  }
  isShowSpinnerOnVariantChange() {
    return this.shopOptions.shopify_spinner_on_variant_change || false
  }
  isLinkPrintPdf() {
    return this.shopOptions.shopify_link_print_pdf || false
  }
  isLinkAssets() {
    return this.shopOptions.shopify_link_assets || false
  }
  isUseSaveButton() {
    const urlParams = this.getUrlParams()

    if(urlParams && urlParams.has('sbs')) {
      return true
    }
    return this.shopOptions.shopify_use_save_button || false
  }
  isSaveButtonReplaceAtc() {
    return this.shopOptions.shopify_save_button_replace_atc?.toString() != "false"
  }
  isAttachEditLink() {
    return this.shopOptions.shopify_add_edit_link != false
  }
  isNoTextCount() {
    return this.shopOptions.shopify_no_text_count || false
  }
  isNoImageCount() {
    return this.shopOptions.shopify_no_image_count || false
  }
  isAlwaysKeepProduct() {
    return this.shopOptions.shopify_always_keep_product || false
  }
  isCacheTextValues() {
    return this.shopOptions.shopify_cache_text_value || false
  }
  isCacheColorValues() {
    return this.shopOptions.shopify_cache_color_value || false
  }
  isOpenDesignerOnProduct() {
    const urlParams = this.getUrlParams()

    if(urlParams && urlParams.has('open_designer_on_product')) {
      return true
    }
    return this.shopOptions.shopify_open_designer_on_product || false
  }
  isDynamicViewsEnabled() {
    return this.shopOptions.enableDynamicViews || false
  }
  
  /**
   * @deprecated use isOpenDesignerOnProduct instead
   *
   * @returns {boolean} true if the designer should be opened when editing a product
   */
  isOpenDesignerWhenEdit() {
    return this.shopOptions.shopify_open_designer_when_edit?.toString() != "false"
  }
  isDoneAsAtc() {
    return this.shopOptions.shopify_done_as_atc || false
  }
}
