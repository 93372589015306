import FpdAdapter from '../services/fpd-adapter.js'

import { each, some, isNil } from 'lodash'
import $ from 'jquery'
/**
 * FPDManager - still a bit unclear what this does.
 *
 * So far holds fpd instance.
 */
export default class FPDManager {
  static createDesigner($fpdNode, options) {
    if(isNil($fpdNode)) {
      $fpdNode = $("<div id='fpd'></div>")
    }
    FPDManager._$fpdNode = $fpdNode
    FPDManager._instance = new FPDManager.FancyProductDesigner($fpdNode[0], options)

    FPDManager._instance.addEventListener('ready', () => {
      each(FPDManager._designerReadyCallbacks, callback => callback())
    })

    // former uiSet event
    FPDManager._instance.addEventListener('productCreate', () => {
      each(FPDManager._uiSetCallbacks, callback => callback())
    })
    FPDManager._instance.addEventListener('productCreate', () => {
      each(FPDManager._productCreateCallbacks, callback => callback())
    })
  }

  static setupPlus() {
    // FPDManager.FancyProductDesignerPlus.setup(FPDManager._$fpdNode, FPDManager.instance);
  }

  static _instance = null
  static _fpdClassesMap = {}
  static _fpdCustomizedPolicy = () => some(FPDManager._instance.viewInstances, 'fabricCanvas.isCustomized')
  static _designerReadyCallbacks = [FPDManager.initEventEmitters]
  static _uiSetCallbacks = []
  static _productCreateCallbacks = []

  static get instance() {
    return FPDManager._instance
  }
  static set instance(instance) {
    FPDManager._instance = instance
  }
  static get wrappedInstance() {
    return new FpdAdapter(FPDManager.instance)
  }
  static get translatedUI() {
    return FPDManager._instance.translatedUI
  }
  static get FancyProductDesigner() {
    return FPDManager._fpdClassesMap['FancyProductDesigner']
  }
  static get FancyProductDesignerPlus() {
    return FPDManager._fpdClassesMap['FancyProductDesignerPlus']
  }
  static get currentFabricCanvas() {
    return FPDManager.instance.currentViewInstance.fabricCanvas
  }
  static addMappedClass(name, klass) {
    FPDManager._fpdClassesMap[name] = klass
  }
  static set fpdClassesMap(mapObject) {
    FPDManager._fpdClassesMap = mapObject
  }

  static set fpdCustomizedPolicy(policyFunction) {
    FPDManager._fpdCustomizedPolicy = policyFunction
  }

  static addDesignerReadyCallback(callback) {
    FPDManager._designerReadyCallbacks.push(callback)
  }

  static addUiSetCallback(callback) {
    FPDManager._uiSetCallbacks.push(callback)
  }

  static addProductCreateCallback(callback) {
    FPDManager._productCreateCallbacks.push(callback)
  }

  static initEventEmitters() {
    if(typeof FPDManager.instance !== 'object') {
      return
    }

    FPDManager.instance.addEventListener('elementModify', (event) => {
      const element = event.detail.element
      const options = event.detail.options
      if(!element._ignore) {
        const isCustomized = FPDManager._fpdCustomizedPolicy()
        $(document).trigger('fpd:view:modified', { isCustomized: isCustomized, element: element })
      }
    })
    FPDManager.instance.addEventListener('fabricObject:added', (event) => {
      const element = event.detail.element
      const options = event.detail.options
      console.warn('fix event fpd:element:added')
      if(!element._ignore) {
        $(document).trigger('fpd:element:added')
      }
    })
    FPDManager.instance.addEventListener('fabricObject:removed', (event) => {
      const element = event.detail.element
      const options = event.detail.options
      console.warn('fix event fpd:element:removed')
      if(!element._ignore) {
        $(document).trigger('fpd:element:removed')
      }
    })

    FPDManager.instance.addEventListener('viewSelect', (event) => {
      $(document).trigger('fpd:view:selected')
    })

    FPDManager.instance.addEventListener('priceChange', (event) => {
      const elementPrice = FPDManager.instance.currentPrice
      $(document).trigger(
        'fpd:price:change',
        { elementPrice: elementPrice }
      )
    })
  }

  static inferMoneyFormat(shopifyFormat) {
    let fpdMoneyFormat, currency, comma, apostrophe, moneySymbole, symboleBeforeAmount

    comma = shopifyFormat.includes("comma")
    apostrophe = shopifyFormat.includes("apostrophe")
    symboleBeforeAmount = shopifyFormat.indexOf("{{") != 0
    moneySymbole = shopifyFormat.replace(/{{.+}}/,"")

    if (symboleBeforeAmount) {
      currency = `${moneySymbole}%d`
    } else {
      currency = `%d${moneySymbole}`
    }

    if (comma) {
      fpdMoneyFormat = {
        currency: currency, decimalSep: ',', thousandSep: '.'
      }
    }
    if (apostrophe) {
      fpdMoneyFormat = {
        currency: currency, decimalSep: '.', thousandSep: '\''
      }
    }
    fpdMoneyFormat ||= {
      currency: currency, decimalSep: '.', thousandSep: ','
    }

    return fpdMoneyFormat
  }

  static createModule(moduleType, $node, ...other) {
    // const moduleWrapperClass = FPDManager.getModuleClass('ModuleWrapper')
    // return new moduleWrapperClass(FPDManager.instance, $node, moduleType, ...other)
    let moduleClass
    switch(moduleType) {
      case 'products':
        moduleClass = FPDManager.getModuleClass('FPDProductsModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'text':
        moduleClass = FPDManager.getModuleClass('FPDTextModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'designs':
        moduleClass = FPDManager.getModuleClass('FPDDesignsModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'images':
        moduleClass = FPDManager.getModuleClass('FPDImagesModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'layouts':
        moduleClass = FPDManager.getModuleClass('FPDLayoutsModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'text-layers':
        moduleClass = FPDManager.getModuleClass('FPDTextLayersModule')
        return new moduleClass(FPDManager.instance, $node, ...other);
      case 'layers':
          moduleClass = FPDManager.getModuleClass('FPDLayersModule')
          return new moduleClass(FPDManager.instance, $node, ...other);
      case 'image_to_upload':
          moduleClass = FPDManager.getModuleClass('FPDUploadZoneModule');
          return new moduleClass(FPDManager.instance, $node, ...other);
      case 'names-numbers':
          moduleClass = FPDManager.getModuleClass('FPDNamesNumbersModule')
          return new moduleClass(FPDManager.instance, $node, ...other);

    }
  }

  static getModuleClass(className) {
    return FPDManager._fpdClassesMap[className]
  }

  static doAction($element) {
    FPDManager._instance.actions.doAction($element)
  }
}
