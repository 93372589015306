import { isNil, isEmpty, filter, each, get, set, has, merge, sortBy, map } from 'lodash'
import $ from 'jquery'
import fetch from 'cross-fetch'

/**
 * Product Model allows loading a Fancy Product from the fpd admin.
 *
 * Each instance represents one product and can't be changed. The url is
 * build from the product page.
 */
export default class ProductModel {

  static buildFromUrl(url, productPage) {
    const instance = new ProductModel(productPage)
    instance.productUrl = instance.inferUrl(url)

    return instance
  }

  static _fpdProductJSON = null
  static _loadingPromise = null

  constructor(productPage) {
    this.productPage = productPage
    this._productUrl = null
  }

  get optionsManager() {
    return this.productPage.optionsManager
  }

  get shopifyProduct() {
    return this.productPage.getShopifyProduct()
  }

  get fpdAdminUrl() {
    return this.optionsManager.fpdAdminUrl
  }

  get shopifyDomain() {
    return this.optionsManager.shopifyDomain
  }

  get subscription() {
    return this.optionsManager.subscription
  }

  get fpdProductJSON() {
    return ProductModel._fpdProductJSON
  }

  set fpdProductJSON(newJson) {
    ProductModel._fpdProductJSON = newJson
  }

  get productUrl() {
    return this._productUrl
  }

  set productUrl(newUrl) {
    if(!isNil(this.productUrl)) {
      throw new Error(`Product url present: ${this.productUrl}. Can't be overwritten.`)
    }
    this._productUrl = newUrl
  }

  getLanguageJson() {
    return get(this.fpdProductJSON, 'plugin_options.langJSON', {})
  }

  updatePluginOptions(path, value) {
    set(this.fpdProductJSON, `plugin_options.${path}`, value)
  }


  /**
   * Infers url for product from the enviroment.
   *
   * Uses the current or given url and multiple variables from the product page.
   *
   * @param  {String} currentUrl Url defaults to window.location.ref
   * @return {String}            Infered product url
   */
  inferUrl(currentUrl = window.location.href) {
    let searchParams = new URLSearchParams(new URL(currentUrl).search)
    let productUrl = `${this.fpdAdminUrl}/api/hosted/shop_product_designer/`

    let productId = this.getProductIdFromParamsOrStorageOrProduct(searchParams)
    productUrl = productUrl + `?shop_product_id=${productId}`

    let variantId = this.getVariantIdFromParamsOrStorage(searchParams)
    if(!isNil(variantId)){
      productUrl = productUrl + `&variant_product_id=${variantId}`
    }

    productUrl = productUrl + `&plan=${this.subscription || 'small'}`
    productUrl = productUrl + `&shop_domain=${this.shopifyDomain}`

    return productUrl
  }

  /**
   * Loads fancy product json from fpd admin.
   *
   * Infers url or uses set one. The product json is set to
   * this.fpdProductJSON.
   *
   * @return {Promise} Loading Promise
   */
  load(forceLoading = false) {
    if(isNil(this.productUrl)) {
      this.productUrl = this.inferUrl()
    }

    if(!forceLoading && (this.fpdProductJSON || ProductModel.loadingPromise)) {
      return ProductModel.loadingPromise || Promise.resolve()
    }

    ProductModel.loadingPromise = window.fetch(this.productUrl).then(response => {
      return response.json()
    }).then(productJSON => {
      if(isEmpty(productJSON) || isEmpty(get(productJSON, 'plugin_options.productsJSON'))) {
        this.loadingPromise = null
        throw new Error(`No fancy product exists for ${this.productUrl}.`)
      }
      this.fpdProductJSON = productJSON
      this.afterLoadingProcessing()
    }).finally(() => {
      ProductModel.loadingPromise = null
    })

    return ProductModel.loadingPromise
  }

  afterLoadingProcessing() {
    this.fpdProductJSON.plugin_options = merge(
      this.optionsManager.getDefaultPluginOptions(),
      this.fpdProductJSON.plugin_options
    )

    this.fpdProductJSON.plugin_options.designsJSON = this.mergeAllDesigns(
      this.fpdProductJSON.plugin_options.designsJSON
    )

    if(!isEmpty(this.fpdProductJSON.shopify)) {
      this.optionsManager.updateTaFromOptions(this.fpdProductJSON.shopify)
    }

    if(!isEmpty(this.optionsManager.getFpdOverwrite())) {
      each(this.optionsManager.getFpdOverwrite(), (value, key) => {
        set(this.fpdProductJSON.plugin_options, key, value)
      })
    }

    if(this.optionsManager.hasCallback('afterProductLoaded')) {
      this.fpdProductJSON = this.optionsManager.invokeCallback('afterProductLoaded', this.fpdProductJSON)
    }
  }

  /**
   * Merging FPD.allDesignsJSON into current plugin options.
   * Either picking the categories selected in the product or taking all.
   * Also sorting the design by title.
   *
   * @param {Object} designsJSON given designes
   * @return {Object} designsJSON merged designs
   *
   */
  mergeAllDesigns(designIds) {
    let designsJSON;
    if(window.FPD.allDesignsJSON) {
      if(isNil(designIds)) {
        designsJSON = window.FPD.allDesignsJSON;
      } else {
        designsJSON = filter(window.FPD.allDesignsJSON, (designCategory) => {
          return designIds.includes(designCategory.ID)
        })
      }
    }

    if(this.optionsManager.isSortDesignsByTitle()) {
      designsJSON = sortBy(designsJSON, 'title')
      designsJSON = map(designsJSON, (e) => {
        e.designs = sortBy(e.designs, 'title')
        return e
      })
    }

    return designsJSON
  }

  getProductIdFromParamsOrStorageOrProduct() {
    return this.shopifyProduct.id
  }

  getVariantIdFromParamsOrStorage(searchParams) {
    let variantId = null
    if(isNil(variantId) && searchParams.has('variant')) {
      variantId = searchParams.get('variant')
    }

    return variantId
  }
}
