import $ from 'jquery'
import { each, isNil, includes } from 'lodash'
import FPDManager from './fpd-manager.js'

export default class ColorValueCacher  {
  constructor(productPage) {
    this.productPage = productPage
    this.etc = {}
  }

  get optionsManager() {
    return this.productPage.optionsManager
  }

  initListener() {
    if(this.optionsManager.isCacheColorValues()) {
      $(document).on('fpd:view:modified', this.viewModifiedCallback.bind(this))
      $(document).on('fpd:view:selected', this.viewSelectCallback.bind(this))
    }
  }

  viewModifiedCallback(_event, data) {
    const element = data.element
    if(element.isSVG()) {
      return
    }
    const eId = element.colorLinkGroup || element.title
    const eValue = element.fill
    if(element.fill != element.originParams.fill) {
      console.debug("update color cache for:", eId, "to:", eValue)
      this.etc[eId] = { value: eValue, shouldSet: false }
    }

  }

  viewSelectCallback(_event) {
    FPDManager.instance.getElements().forEach(element => {
      if(element.isSVG()) {
        return
      }

      const eId = element.colorLinkGroup || element.title
      if(!isNil(this.etc[eId])) {
        console.debug("setting color for:", eId, this.etc[eId])
        const fabricCanvas = FPDManager.instance.currentViewInstance.fabricCanvas
        FPDManager.currentFabricCanvas.setElementOptions(
          {'fill': this.etc[eId].value},
          element
        )
      } else {
        // console.debug("no value for", eId)
      }
    })
  }
}
