import $ from 'jquery'
import { each, isNil, includes } from 'lodash'
import FPDManager from './fpd-manager.js'

export default class TextValueCacher  {
  constructor(productPage) {
    this.productPage = productPage
    this.etc = {}
  }

  get optionsManager() {
    return this.productPage.optionsManager
  }

  initListener() {
    if(this.optionsManager.isCacheTextValues()) {
      $(document).on('fpd:view:modified', this.viewModifiedCallback.bind(this))
      $(document).on('fpd:view:selected', this.viewSelectCallback.bind(this))
    }
  }

  viewModifiedCallback(_event, data) {
    // console.debug("fpd:view:modified", data)
    const element = data.element
    if(!includes(['curvedText', 'i-text'], element.type)) {
      return
    }

    const eId = element.textLinkGroup || element.title
    const eValue = element.text
    if(element.text != element.originParams.text) {

      console.debug("update cache for: ", eId, "to:", eValue)
      this.etc[eId] = { value: eValue, shouldSet: false }
    }

  }

  viewSelectCallback(_event) {
    FPDManager.instance.getElements().forEach(element => {
      if(element.type != 'i-text') {
        return
      }

      const eId = element.textLinkGroup || element.title
      if(!isNil(this.etc[eId])) {
        // console.debug("setting for", eId, this.etc[eId])
        FPDManager.currentFabricCanvas.setElementOptions(
          {'text': this.etc[eId].value},
          element
        )
      } else {
        // console.debug("no value for", eId)
      }
    })
  }
}
