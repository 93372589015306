import $ from 'jquery'
import { each, isNil, merge } from 'lodash'
import ProductPageFormOperator from './product-page-form-operator.js'
import FPDManager from './fpd-manager.js'
import { AddToCartError } from '../errors'

/**
 *  Responsible for
 *  - button magic
 *  - prepare add to cart
 *  - save to form
 *  - add to cart
 *
 * Open Questions:
 * - how to handle different atc approches (submit form, json, to form)
 * - how to handle different triggers (purly by event, replace button, normal click)
 * - how to integrate save with above points
 */
export default class AtcOperator extends ProductPageFormOperator {

  constructor(productPage) {
    super(productPage)
    this.$newButtons = []
    this.$orgButtons = []
    this.atcStrategy = null
  }

  initListener() {
    if(this.optionsManager.isUseSaveButton()) {
      return
    }

    this.replaceButton()
    this.initAtcStateListener()
    this.setAtcStrategy()
    this.setAfterAtcBehavior()
  }

  getButtons() {
    return this.$newButtons
  }

  setAtcStrategy() {

    let strategySelection = `${this.productPage.getAtcBehavior()}-${this.optionsManager.getAtcStrategy()}`

    switch(strategySelection) {
      case 'add-save_to_form':
        console.debug('atc strategy - add-save_to_form')
        this.atcStrategy = this.addProductToForm
        break
      case 'add-add_form_data':
        console.debug('atc strategy - add-add_form_data')
        this.atcStrategy = this.addProductToCartFormData
        break
      case 'add-add_json':
        console.debug('atc strategy - add-add_json')
        this.atcStrategy = this.addProductToCartJson
        break
      case 'add-lic-event':
        console.debug('atc strategy - add-lic-event')
        this.atcStrategy = this.triggerLicEvent
        break
      case 'update-save_to_form':
        console.debug('atc strategy - update-save_to_form')
        this.atcStrategy = this.updateProductToForm
        break
      case 'update-add_form_data':
        console.debug('atc strategy - update-add_form_data')
        this.atcStrategy = this.updateProductToCartFormData
        break
      default:
        throw `${strategySelection} - Unknown atc strategy.`
    }
  }

  setAfterAtcBehavior() {
    switch(this.optionsManager.getAfterAtcStrategy()) {
      case 'redirect_to_cart':
        console.debug('after atc strategy - redirect_to_cart')
        this.afterAtcBehavior = () => window.location.href = "/cart"
        break
      case 'refresh_page':
        console.debug('after atc strategy - refresh_page')
        this.afterAtcBehavior = () => window.location.reload()
        break
      case 'trigger_event':
        console.debug('after atc strategy - trigger_event')
        this.afterAtcBehavior = (addedProduct) => $(document).trigger('fpd:addToCart', addedProduct)
        break
      case 'submit_form':
        console.debug('after atc strategy - submit_form')
        this.afterAtcBehavior = () => {
          if(this.optionsManager.hasCallback('addProductToForm')) {
            this.optionsManager.invokeCallback('addProductToForm', this.productPage.$addToCartForm)
          } else {
            this.productPage.$addToCartForm.submit()
          }
        }
        break
      default:
        throw 'No after atc strategy defined'
    }
  }

  initAtcStateListener() {
    const that = this
    let initiallyDisabled = false

    if(this.optionsManager.isDisableAtcUntilCustomization()) {
      initiallyDisabled = true

      $(document).on('fpd:view:modified', (evt, details) => {
        if(details.isCustomized == true) {
          that.setAtcButtonsDisabled(false)
        } else {
          that.setAtcButtonsDisabled(true)
        }
      })
    }

    that.setAtcButtonsDisabled(initiallyDisabled)
  }

  setAtcButtonsDisabled(disabled) {
    $(document).trigger("fpd:addToCartButtonState", { disabled: disabled })

    each(this.$newButtons, ($newButton) => {
      $newButton.prop('disabled', disabled)
    })
  }

  replaceButton() {
    this.$orgButtons = this.getAtcButtons()

    const that = this
    each(this.$orgButtons, ($orgButton) => {
      $orgButton = $($orgButton)
      let $newButton = $orgButton.clone()
      $newButton.on('click.fpd', that.clickHandler.bind(that))
      $orgButton.replaceWith($newButton)
      that.$newButtons.push($newButton)
    })
  }

  triggerAtcClick() {
    each(this.$newButtons, ($newButton) => {
      $newButton.trigger('click')
    })
  }

  clickHandler(evt) {
    evt.preventDefault()
    const that = this
    that.setAtcButtonsDisabled(true)

    that.invokeBeforeAddToCartCallback()

    const shopifyProduct = that.getShopifyProduct()
    const variant = that.getCurrentShopifyVariant()
    const forceCustomProduct = that.isForceCustomProduct()

    that.productPage.licManager.processLicCreation(shopifyProduct, variant, forceCustomProduct)
      .then((lineItemCache) => {
        return that.atcStrategy(lineItemCache, shopifyProduct)
        .then((addedProduct) => {
          if(that.optionsManager.hasCallback('addToCart')) {
            that.optionsManager.invokeCallback('addToCart', addedProduct)
          }
          return that.afterAtcBehavior(addedProduct)
        })
      }).catch(error => {
        console.warn(error)
        if(error.name == 'AddToCartError') {
          this.showOutOfBoundaryModal()
        } else {
          throw error
        }
      }).finally(() => {
        that.setAtcButtonsDisabled(false)
      })
  }

  showOutOfBoundaryModal() {
    const outElements = FPDManager.instance.getElements().filter(e => e.isOut)
    let content = `<h3>Sorry. We couldn't add your product to cart.</h3>`
    if(outElements.length > 0) {
      content += `<p>The following elements are out of the printable area.</p>`
      content += `<ul>`
      outElements.forEach(e => {
        content += `<li>${e.title}</li>`
      })
      content += `</ul>`
    }
    content += `<p>Please move all elements into their containment.</p>`
    FPD.func.FPDUtil.showModal(content)
  }

  addProductToForm(lineItemCache, shopifyProduct) {
    let properties = this.buildProperties(lineItemCache, shopifyProduct)

    let formData = new FormData(this.productPage.$addToCartForm[0]) // access non jq node

    $.each(properties, (key, value) => {
      if(!key.includes('properties')) {
        key = `properties[${key}]`
      }
      this.replaceOrCreateInput(key, value)
    })

    this.replaceOrCreateInput('id', this.getVariantId(lineItemCache))

    return Promise.resolve({})
  }

  addProductToCartFormData(lineItemCache, shopifyProduct) {
    let properties = this.buildProperties(lineItemCache, shopifyProduct)

    let formData = new FormData(this.productPage.$addToCartForm[0]) // access non jq node

    $.each(properties, (key, value) => {
      if(!key.includes('properties')) {
        key = `properties[${key}]`
      }
      formData.set(key, value)
    })
    formData.set('id', this.getVariantId(lineItemCache))

    return fetch('/cart/add', {
      method: 'POST',
      body: formData
    })
  }

  addProductToCartJson(lineItemCache, shopifyProduct) {
    let properties = this.buildProperties(lineItemCache, shopifyProduct)

    const payload = { properties: properties }

    let formPropertiesArray = this.productPage.$addToCartForm.serializeArray()

    const excludedFieldNames = this.optionsManager.getAtcExcludedFieldNames(['form_type', 'utf8'])
    $.each(formPropertiesArray, (index, property) => {
      if(!excludedFieldNames.includes(property.name)) {
        if (property.name.startsWith('properties[')) {
          const newName = property.name.replace('properties[', '').replace(']', '')
          payload['properties'][newName] = property.value
        } else {
          payload[property.name] = property.value
        }
      } 
    })

    payload['id'] = this.getVariantId(lineItemCache)
    
    return fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      return response.json();
    })
  }

  updateProductToForm(lineItemCache, shopifyProduct) {
    const existingVariantId = this.getVariantId(this.productPage.existingLic)
    const lineItemDeleteId = this.getLineItemKey() || existingVariantId

    return fetch('/cart/change.js', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ quantity: 0, id: `${lineItemDeleteId}` })
    }).then(() => {
      return this.addProductToForm(lineItemCache, shopifyProduct)
    })
  }

  updateProductToCartFormData(lineItemCache, shopifyProduct) {
    const existingVariantId = this.getVariantId(this.productPage.existingLic)
    const lineItemDeleteId = this.getLineItemKey() || existingVariantId

    return fetch('/cart/change.js', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ quantity: 0, id: `${lineItemDeleteId}` })
    }).then(() => {
      return this.addProductToCartFormData(lineItemCache, shopifyProduct)
    })
  }

  triggerLicEvent(lineItemCache, shopifyProduct) {
    let properties = this.buildProperties(lineItemCache, shopifyProduct)
    properties['id'] = `${this.getVariantId(lineItemCache)}`
    $(document).trigger('fpd:atcPrepared', properties)

    return Promise.resolve(properties)
  }

  getLineItemKey(currentUrl = window.location.href) {
    const urlParams = new URLSearchParams(new URL(currentUrl).search)
    return urlParams.get('li-key')
  }

  invokeBeforeAddToCartCallback() {
    if(this.optionsManager.hasCallback('beforeAddToCart')) {
      this.optionsManager.invokeCallback('beforeAddToCart', this.productPage.$addToCartForm)
    }
  }
}
